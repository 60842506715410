<script setup>
import { useRoute } from 'vue-router'
import { computed, onBeforeMount, onMounted, shallowRef } from 'vue'
import Navigation from '~/components/Navigation.vue'
import { useNavigationStore } from '~/store/navigation.js'
import { Close } from '@element-plus/icons-vue'
import { useAgentStore } from '~/store/agent.js'
import { useAuthStore } from '~/store/auth'

const route = useRoute()

const agentStore = useAgentStore()

const navigationStore = useNavigationStore()

const authStore = useAuthStore()

const navigation = ref([])

const isExpanded = computed(() => navigationStore.isExpanded)

const logo = computed(() => (isExpanded.value ? '/images/avrillo-logo.png' : '/images/favicon.png'))

const mobileOpen = computed(() => navigationStore.mobileOpen)

const isInternal = computed(() => authStore.isInternalUser)
const isInLeague = computed(() => authStore?.fantasyLeagueIds !== null || authStore.isInternalUser)

function toggleHoverExpanded(isExpanded) {
  navigationStore.toggleHoverExpanded(isExpanded)
}

function toggleExpanded() {
  navigationStore.toggleExpanded()
}

onBeforeMount(() => {
  navigation.value = [
    {
      title: 'AML/ID Reports',
      subtitle: '',
      href: '/aml-check/seller',
      icon: markRaw(ElIconNotebook),
      visible: (agentStore.amlSaleVisible || agentStore.amlPurchaseVisible) && !agentStore.useAltIdFlow,
      alternative: false,
      isActive: false,
      children: [
        {
          title: 'Seller AML/ID Reports',
          href: '/aml-check/seller',
          visible: agentStore.amlSaleVisible,
          icon: markRaw(ElIconUser),
          isActive: false,
        },
        {
          title: 'Buyer AML/ID Reports',
          href: '/aml-check/buyer',
          visible: agentStore.amlPurchaseVisible,
          icon: markRaw(ElIconUser),
          isActive: false,
        },
      ],
    },
    {
      title: 'Stats Summary',
      subtitle: 'Our statistics summary',
      href: '/',
      icon: markRaw(ElIconPieChart),
      visible: true,
      isActive: false,
    },
    {
      title: 'Self-instruct / Quote',
      subtitle: '60 Days Risk-free',
      href: '/quote-app',
      icon: markRaw(ElIconCirclePlus),
      visible: true,
      isActive: false,
    },
    {
      title: agentStore.useAltIdFlow ? 'Material Info Pack<br />and AML:ID Report' : 'Ask AVRillo to Quote',
      subtitle: agentStore.useAltIdFlow ? '' : 'Request a Quote',
      href: '/quote-requests',
      icon: markRaw(ElIconPosition),
      visible: true,
      isActive: false,
    },
    {
      title: 'Material Information Packs',
      subtitle: '',
      href: '/material-information-packs',
      icon: markRaw(ElIconPosition),
      visible: !agentStore.useAltIdFlow,
      isActive: false,
    },
    {
      title: 'PSOs',
      subtitle: 'Potential Solutions Offered',
      href: '/cases?status=commercial_views',
      icon: markRaw(ElIconView),
      visible: true,
      isActive: false,
    },
    {
      title: 'Push to Ex.',
      subtitle: 'List 70% Ready',
      href: '/cases?status=hot',
      icon: markRaw(ElIconTrendCharts),
      visible: true,
      isActive: false,
    },
    {
      title: 'Current Cases',
      icon: markRaw(ElIconDocument),
      visible: true,
      href: '/cases',
      isActive: false,
      children: [
        { title: 'Before Exchange', href: '/cases?status=to_exchange', icon: markRaw(ElIconList), visible: true, isActive: false },
        {
          title: 'AML Closed Case',
          href: '/cases?status=awaiting_aml_docs',
          icon: markRaw(ElIconList),
          visible: true,
          isActive: false,
        },
        {
          title: 'Exchange to Complete',
          href: '/cases?status=exchanged_not_completed',
          icon: markRaw(ElIconList),
          visible: true,
          isActive: false,
        },
        { title: 'All Not Completed', href: '/cases?status=not_completed', icon: markRaw(ElIconList), visible: true, isActive: false },
        { title: 'Exchanged This Month', href: '/cases?status=exchanged', icon: markRaw(ElIconList), visible: true, isActive: false },
        { title: 'Completed This Month', href: '/cases?status=completed', icon: markRaw(ElIconList), visible: true, isActive: false },
      ],
    },
    {
      title: 'Quoted This Month',
      subtitle: 'Quotes this month',
      href: '/quote-cases',
      icon: markRaw(ElIconOperation),
      visible: true,
      isActive: false,
    },
    {
      title: 'Top Negotiator Stats',
      subtitle: 'Top stats for negotiators',
      href: '/negotiators-board',
      icon: markRaw(ElIconTrophy),
      visible: true,
      isActive: false,
    },
    {
      title: 'Agents Champ. Leagues',
      subtitle: 'Highest performing agents',
      href: '/estate-agents-champion-leagues',
      icon: markRaw(ElIconTrophy),
      visible: isInLeague,
      isActive: false,
    },
    {
      title: 'Negotiator Champ. League',
      subtitle: 'Highest performing negotiators',
      href: '/negotiators-league',
      icon: markRaw(ElIconTrophy),
      visible: isInternal,
      isActive: false,
    },
    {
      title: 'Video Guides',
      subtitle: 'Free Video Guides',
      href: '/video-guides',
      icon: markRaw(ElIconPieChart),
      visible: true,
      isActive: false,
    },
    { title: 'FAQ', subtitle: 'Frequently asked questions', href: '/faqs', icon: markRaw(ElIconPieChart), visible: true, isActive: false },
    { title: 'Income Stats', subtitle: '', href: '/income-stats', icon: markRaw(ElIconMoney), visible: true, isActive: false },
  ]
})

onMounted(() => {
  updateNavigation()
})

watch(
  () => route.path,
  () => {
    updateNavigation()
  },
  { deep: true },
)

watch(
  () => authStore.fantasyLeagueIds,
  () => {
    updateNavigation()
  },
  { deep: true, immediate: true },
)

function updateNavigation() {
  const previousActiveIndex = navigation.value.findIndex((navItem) => navItem.isActive)

  navigation.value.forEach((item, index) => {
    if (item.children) {
      item.children.forEach((child, childIndex) => {
        navigation.value[index].children[childIndex].isActive = isActive(child.href)
      })

      const active = item.children.some((child) => child.isActive)

      navigation.value[index].isActive = active

      const currentElem = document.querySelector(`[data-id="collapse${index}"]`)

      if (currentElem) {
        if (currentElem.getAttribute('data-headlessui-state') !== 'open' && active) {
          currentElem.querySelector(['[id^="headlessui-disclosure-button-"]']).click()
        }
      }

      if (previousActiveIndex !== -1 && previousActiveIndex !== index && item.isActive) {
        const previousElem = document.querySelector(`[data-id="collapse${previousActiveIndex}"]`)

        if (previousElem) {
          if (previousElem.getAttribute('data-headlessui-state') === 'open') {
            previousElem.querySelector(`[data-id="collapseClose${previousActiveIndex}"]`).click()
          }
        }
      }
    } else {
      navigation.value[index].isActive = isActive(item.href)

      if (navigation.value[index].isActive) {
        navigation.value
          .filter((navItem) => navItem.children)
          ?.forEach((navItem, navIndex) => {
            const originalIndex = navigation.value.findIndex((originalItem) => originalItem === navItem)

            const currentElem = document.querySelector(`[data-id="collapse${originalIndex}"]`)

            if (currentElem) {
              if (currentElem.getAttribute('data-headlessui-state') === 'open') {
                document.querySelector(`[data-id="collapseClose${originalIndex}"]`).click()
              }
            }
          })
      }
    }
  })
}

function isActive(href) {
  const paths = route.path.split('/').filter((item) => item !== '')
  const query = route.query
  const hrefQuery = href.split('?')[1]
  const hrefSplit = href.split('/').filter((item) => item !== '')

  if (paths.length === 0) {
    return route.path === href
  } else {
    const queryKeys = Object.keys(query)

    if (queryKeys.length > 0) {
      if (hrefQuery) {
        const hrefQueryParams = hrefQuery.split('&')
        const hrefQueryObj = {}

        hrefQueryParams.forEach((param) => {
          const [key, value] = param.split('=')
          hrefQueryObj[key] = value
        })

        return queryKeys.some((key) => query[key] === hrefQueryObj[key])
      } else {
        return route.path === href
      }
    } else {
      return route.path === href || (paths[0] === hrefSplit[0] && paths[1] === hrefSplit[1] && paths[2] === 'create')
    }
  }
}
</script>

<template>
  <aside
    :class="isExpanded ? 'w-64 px-4' : 'w-20 px-3'"
    class="hidden h-full flex-col gap-6 overflow-auto border-r bg-white py-6 text-sm shadow transition-all duration-300 ease-in-out lg:flex"
    @mouseenter="toggleHoverExpanded(true)"
    @mouseleave="toggleHoverExpanded(false)"
  >
    <header class="flex items-center gap-3">
      <NuxtLink to="/">
        <img :src="logo" :class="isExpanded ? 'h-10' : 'mx-auto h-8'" class="!w-auto flex-shrink-0 cursor-pointer" />
      </NuxtLink>

      <el-button
        :icon="navigationStore.expanded ? ElIconCircleClose : ElIconCirclePlus"
        :class="(isExpanded ? '' : 'hidden ') + (navigationStore.expanded ? 'bg-primary text-white' : 'text-primary')"
        class="ml-auto border-primary"
        circle
        @click="toggleExpanded"
      />
    </header>

    <Navigation :navigation />
  </aside>

  <div
    :class="mobileOpen ? 'opacity-50' : 'invisible opacity-0'"
    class="fixed left-0 top-0 z-10 h-full w-full bg-black transition-all duration-300 lg:hidden"
    @click="navigationStore.closeMobile"
  />
  <aside
    :class="mobileOpen ? 'left-0' : '-left-80'"
    class="fixed z-20 flex h-full w-80 flex-col gap-6 overflow-auto border-r bg-white px-4 py-6 text-sm shadow transition-all duration-300 ease-in-out lg:hidden"
  >
    <header class="flex items-center gap-3">
      <NuxtLink to="/">
        <img src="/images/avrillo-logo.png" class="h-10 flex-shrink-0 cursor-pointer" />
      </NuxtLink>

      <el-icon class="ml-auto text-gray-500" size="20" @click="navigationStore.closeMobile">
        <Close />
      </el-icon>
    </header>

    <Navigation :navigation />
  </aside>
</template>
